import { mapGetters, mapActions } from 'vuex'
import authMixin from '@/mixins/auth-mixin'
const AddImagesForm = () => import(/* webpackChunkName = "c-add-banner-form" */'@/components/AddImagesForm')
const ImagesItem = () => import(/* webpackChunkName = "c-images-item" */ '@/components/ImagesItem')

export default {
  name: 'Images',
  mixins: [authMixin],
  components: {
    AddImagesForm,
    ImagesItem
  },
  data () {
    return {
      visibleAddForm: false,
      selectedType: null,
      page: 1,
      params: {
        page: 1,
        itemPerPage: 100
      },
      search: ''
    }
  },
  created () {
    this.getImagesAction({
      success: () => {},
      data: this.params
    })
  },
  computed: {
    ...mapGetters('user', ['token']),
    ...mapGetters('image', ['images', 'types', 'selectedImagesTitle']),
    isLastPage () {
      return this.images.length < this.params.itemPerPage
    },
    isFirstPage () {
      return this.params.page === 1
    }
  },
  methods: {
    ...mapActions('image', ['getImagesAction', 'deleteImagesAction', 'deleteMultipleImagesAction']),
    handleSuccessDelete () {
      this.getImagesAction({
        success: () => {},
        type: this.selectedType
      })
      this.$swal({
        icon: 'success',
        title: 'Success Delete',
        showConfirmButton: false,
        timer: 1500
      })
    },
    handleDeleteImage (id) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan dapat mengembalikan ini!',
        icon: 'warning',
        confirmButtonText: 'Yes, hapus!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteImagesAction({
            success: this.handleSuccessDelete,
            data: {
              id: id,
              token: this.token
            }
          })
        }
      })
    },
    handleMarkedDeleteImages () {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        html: `<div>Anda tidak akan dapat mengembalikan ini! </div><div>(${this.selectedImagesTitle?.join(', ')})</div>`,
        icon: 'warning',
        confirmButtonText: 'Yes, hapus!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteMultipleImagesAction({
            success: this.handleSuccessDelete,
            token: this.token
          })
        }
      })
    },
    toggleAddForm () {
      this.visibleAddForm = !this.visibleAddForm
    },
    changeType (type) {
      if (type === 'all') {
        delete this.params.type
        this.getImagesAction({
          success: () => {},
          data: this.params
        })
      } else {
        this.params.type = type
        this.getImagesAction({
          success: () => {},
          data: this.params
        })
      }
    },
    handlePagination (direction) {
      this.params = {
        ...this.params,
        page: direction === 'left' ? this.params.page - 1 : this.params.page + 1
      }
      this.getImagesAction({
        success: () => {},
        data: this.params
      })
    },
    handleSearchImages () {
      this.params = {
        ...this.params,
        page: 1,
        search: this.search
      }
      this.getImagesAction({
        success: () => {},
        data: this.params
      })
    },
    resetSearch () {
      this.search = ''
      this.params = {
        ...this.params,
        page: 1
      }
      delete this.params.search
      this.getImagesAction({
        success: () => {},
        data: this.params
      })
    }
  }
}
